import { TypeConfigColors } from "src/types/TypeConfigTheme";

const defaults: TypeConfigColors = {
  "vf-colors-primary": "#151C15",
  "vf-colors-primary-hover": "#151C15",
  "vf-colors-text-primary": "#FFFFFF",
};

const convertThemeColorsToCssVariables = (colors?: TypeConfigColors): string => {
  return Object.entries({ ...defaults, ...colors }).reduce((accumulator, [variableName, variableValue]) => {
    return accumulator + `--${variableName}: ${variableValue};\n`;
  }, "");
};

export default convertThemeColorsToCssVariables;
