const config = {
  apiEndpointProviders: process.env.REACT_APP_PROVIDERS_ENDPOINT,
  apiEndpointControllers: process.env.REACT_APP_CONTROLLERS_ENDPOINT,
  apiEndpointDialler: process.env.REACT_APP_DIALLER_ENDPOINT,
  apiEndpointWebsocket: process.env.REACT_APP_WEBSOCKETS_ENDPOINT,
  cognitoClientDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoAppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  cognitoAppClientSecret: process.env.REACT_APP_COGNITO_APP_CLIENT_SECRET,
  launchDarklyClientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  jiraBugCollector: process.env.REACT_APP_JIRA_BUG_COLLECTOR,
  jiraSuggestionCollector: process.env.REACT_APP_JIRA_SUGGESTION_COLLECTOR,
};

export default config;
