import { TypeConfigColors } from "./types/TypeConfigTheme";
import { TypeConfigPublic } from "./types/TypeUiConfig";
import convertThemeColorsToCssVariables from "./App/components/ThemeColorStyles/utils/convertThemeColorsToCssVariables";

import staticConfig from "./staticConfig";
import logger from "./utils/logger";
import WebFont from "webfontloader";

function addThemeColorVariablesStyleTag(colors?: TypeConfigColors): void {
  if (!colors) return;
  const cssString = convertThemeColorsToCssVariables(colors);
  const styleTagThemeColorVariables = document.createElement("style");
  styleTagThemeColorVariables.innerHTML = `\n body {\n${cssString}}\n`;
  document.head.appendChild(styleTagThemeColorVariables);
}

export const getPublicConfig = async (): Promise<TypeConfigPublic> =>
  await fetch(staticConfig.apiEndpointControllers + "/config-public").then((result) => result.json());

async function init(): Promise<void> {
  try {
    const config = await getPublicConfig();
    window.publicConfig = config; // attach to window so loading splash can use it
    addThemeColorVariablesStyleTag(config?.theme?.colors);

    WebFont.load({
      google: {
        families: ["Source Sans Pro:400,600,700"],
      },
    });
  } catch (error) {
    logger.warn("Could not load public config");
  }
}

// just to keep TS happy, need to export something, even though we're not using it
export default init;

init();
